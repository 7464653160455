.MuiInputLabel-outlined {
  transform: translate(12px, 14px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
}
.MuiSelect-outlined.MuiSelect-outlined {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

#recaptcha,
#rc-imageselect {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
